/* You can add global styles to this file, and also import other style files */
@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";

*{
  margin: 0;
  padding: 0;
}

body{
   background: #212528;
   color: #fff;
   font-family: 'Circe'; 
}

html, body {
    height: 100%;
}

body {
     margin: 0;
     font-family: Roboto, "Helvetica Neue", sans-serif;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

@font-face {
    font-family: 'Circe';
    src: url('assets/fonts/Circe-Regular.ttf'); 
  }
  
  @font-face {
    font-family: 'CirceBold';
    src: url('assets/fonts/CIRCEBOLD.OTF'); 
  }
  
  @font-face {
    font-family: 'CirceExtraBold';
    src: url('assets/fonts/CIRCEEXTRABOLD.OTF'); 
  }
  
  @font-face {
    font-family: 'CirceExtraLight';
    src: url('assets/fonts/CIRCEEXTRALIGHT.OTF'); 
  }
  
  @font-face {
    font-family: 'CirceLight';
    src: url('assets/fonts/CIRCELIGHT.OTF'); 
  }
  
  @font-face{
    font-family: 'LatoSemibold';
    src: url('assets/fonts/lato-semibold.ttf'); 
  }
  
  
  @font-face{
    font-family: 'LatoBold';
    src: url('assets/fonts/lato-bold.ttf'); 
  }
  
  @font-face{
    font-family: 'Lato';
    src: url('assets/fonts/lato-regular.ttf'); 
  } 
 
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
